<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      permanent
      :clipped="clipped"
      v-if="$vuetify.breakpoint.mdAndUp"
      hide-overlay
    >
      <div class="d-flex mt-4 ml-2">
        <v-avatar color="primary">
          <v-icon light> mdi-account-circle </v-icon>
        </v-avatar>
        <h3 class="ml-2 mt-4 success--text">
          {{ curUser.Fullname }}
        </h3>
      </div>
      <v-divider class="mt-4"></v-divider>

      <v-list-group
        class="success--text"
        :value="false"
        prepend-icon="mdi-table"
        append-icon="mdi-chevron-double-up"
      >
        <template v-slot:activator>
          <b>TABLEs</b>
        </template>
        <v-list dense>
          <v-list-item
            v-for="table in tables"
            :key="table.title"
            :to="{ name: table.link }"
          >
            <v-card-subtitle>{{ table.title }}</v-card-subtitle>
          </v-list-item>
        </v-list>
      </v-list-group>
      <v-list-group
        class="success--text"
        :value="true"
        prepend-icon="mdi-file-document"
        append-icon="mdi-chevron-double-up"
      >
        <template v-slot:activator>
          <b>FORMs</b>
        </template>
        <v-list dense>
          <v-list-item
            v-for="form in forms"
            :key="form.title"
            :to="{ name: form.link }"
          >
            <v-icon>{{ form.icon }}</v-icon>
            <v-card-subtitle>{{ form.title }}</v-card-subtitle>
          </v-list-item>
        </v-list>
      </v-list-group>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-else
      v-model="drawer"
      app
      hide-overlay
      :drawer="drawer"
    >
      <h3 class="text-center mt-4 success--text">{{ curUser.Fullname }}</h3>
      <v-divider class="mt-4"></v-divider>
      <v-list-group
        class="success--text"
        :value="false"
        prepend-icon="mdi-table"
        append-icon="mdi-chevron-double-up"
      >
        <template v-slot:activator>
          <b>TABLEs</b>
        </template>
        <v-list dense>
          <v-list-item
            v-for="table in tables"
            :key="table.title"
            :to="{ name: table.link }"
          >
            <v-card-subtitle>{{ table.title }}</v-card-subtitle>
          </v-list-item>
        </v-list>
      </v-list-group>
      <v-list-group
        class="success--text"
        :value="true"
        prepend-icon="mdi-file-document"
        append-icon="mdi-chevron-double-up"
      >
        <template v-slot:activator>
          <b>FORMs</b>
        </template>
        <v-list dense>
          <v-list-item
            v-for="form in forms"
            :key="form.title"
            :to="{ name: form.link }"
          >
            <v-icon>{{ form.icon }}</v-icon>
            <v-card-subtitle>{{ form.title }}</v-card-subtitle>
          </v-list-item>
        </v-list>
      </v-list-group>
    </v-navigation-drawer>

    <div>
      <router-view></router-view>
    </div>

    <div @click="drawer = 'drawer'" v-if="$vuetify.breakpoint.smAndDown">
      <v-btn fab fixed large bottom left title="show menus" color="primary"
        ><v-icon>mdi-view-list</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      drawer: false,
      clipped: true,
    };
  },
  computed: {
    ...mapState(["curUser", "forms", "tables"]),
  },
};
</script>

<style scoped>
.v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>
